@import "@Static/css/common";

.topFeaturesRoot {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .topFeaturesCard {
        display: flex;
        flex: 1 0 330px;
        max-width: 370px;
        flex-direction: column;
        justify-content: space-between;
        box-shadow: 20px 0px 20px rgba(0, 0, 0, 0.02);

        .cardUpper {
            padding: 18% 18% 0 18%;
        }
    }

    .number {
        font-style: normal;
        font-weight: 600;
        font-size: 44px;
        line-height: 48px;
        margin: 0;
        margin-bottom: 12px;
    }
    .title {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 35px;
        margin: 0;
        margin-bottom: 18px;
        word-wrap: break-word;
    }
    .description {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 27px;
        color: #878686;
        margin: 0;
        margin-bottom: 30px;
    }

    .ctaContainer {
        padding: 0 18% 18%;

        .ctaButton {
            background: #ffffff;
            border: 1.5px solid #1c58f6;
            border-radius: 28px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #1c58f6;
            padding: 10px 26px;

            &:hover {
                background: #1c58f6;
                border: 1.5px solid #1c58f6;
                border-radius: 28px;
                color: #fff !important;
            }
        }
    }
}

.shouldCenterAlign {
    justify-content: center !important;
}

@media (min-width: 768px) {
    .topFeaturesRoot {
        flex-wrap: wrap;
        justify-content: center;
    }
}
@media (max-width: 768px) {
    .topFeaturesRoot {
        flex-direction: column;

        .topFeaturesCard {
            max-width: 100%;
            box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.02);
        }
    }
    .ctaContainer {
        padding: 0 18% 18% 18% !important;
        align-self: self-start;
        text-align: start;
    }
}
