.verticalSliderWrap {
    background-color: #919191;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 90%);

    .container {
        position: relative;
        width: 100vw;
        max-width: 100%;
        height: 80vh;
        overflow: hidden;
        z-index: 1;
        transition: all 0.2s linear;
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 90%);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: #919191;

        &::before {
            content: "";
            position: absolute;
            height: 80vh;
            width: 100vw;
            background: rgba(0, 0, 0, 0.5);
            z-index: 3;
        }

        .sectionTitleContainer {
            position: absolute;
            top: 42%;
            z-index: 3;
            max-width: 45%;

            p:first-child {
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 17px;
                letter-spacing: 0.14em;
                color: #ffffff;
                margin: 0 0 12px 180px;
                text-transform: uppercase;
                overflow-wrap: anywhere;
            }

            p {
                font-style: normal;
                font-weight: 500;
                font-size: 40px;
                line-height: 48px;
                color: #ffffff;
                margin: 0 0 0 180px;
                overflow-wrap: anywhere;
            }
        }

        .centeredLinkPanelStyle {
            justify-content: start;
        }

        .linksPanel {
            position: absolute;
            width: 100%;
            height: 80vh;
            max-height: 80vh;
            left: 0;
            z-index: 4;
            display: flex;
            flex-direction: column;
            height: 100%;
            padding-top: 40px;
            padding-bottom: 80px;
            overflow-y: auto;

            &::-webkit-scrollbar {
                width: 0;
                height: 0;
            }

            &::-webkit-scrollbar-track {
                background-color: transparent;
            }

            &::-webkit-scrollbar-thumb {
                background-color: transparent;
            }

            .links {
                font-style: normal;
                font-weight: 400;
                font-size: 28px;
                line-height: 46px;
                color: #ffffff;
                opacity: 0.4;
                padding-left: 48%;
                padding-right: 2%;
                margin-bottom: 28px;

                &:hover {
                    opacity: 1;
                }

                .linksTitle {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 17px;
                    letter-spacing: 0.1em;
                    color: #ffffff;
                    padding-bottom: 8px;
                }
            }

            .linksDescription {
                overflow-wrap: anywhere;
            }

            .activeLinks {
                opacity: 1 !important;
            }
        }
    }

    .zoomIn {
        animation: zoom-in-zoom-out 0.5s linear;
        z-index: 0;
    }
}

@keyframes zoom-in-zoom-out {
    0% {
        transform: scale(103%);
        opacity: 0.2;
    }
    50% {
        transform: scale(101%);
        opacity: 1;
    }
    100% {
        transform: scale(100%);
    }
}

@media only screen and (max-width: 768px) {
    .sectionDescription {
        margin-bottom: 20px !important;
    }
    .verticalSliderWrap {
        .container {
            .sectionTitleContainer {
                position: relative;
                top: 7%;
                p {
                    margin-left: 22px !important;
                }
                max-width: 100%;
            }
            .linksPanel {
                margin-top: 24px;
                .links {
                    padding: 0 22px 38px 22px;
                    font-size: 24px !important;
                }
                .links:last-child {
                    padding-bottom: 23vh;
                }
            }
        }
    }
}
