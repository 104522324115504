.featuredInSectionContainer {
    width: 80vw;
    margin: auto;
}
.featuredInTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 17px;
    letter-spacing: 0.1em;
    margin-bottom: 50px;
}

.imgClass {
    max-height: 120px;
    max-width: 120px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.gd_carousel_wrapper {
    position: relative;
    padding-bottom: 40px;
}

.brandSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.brandName {
    text-align: center;
    font-size: 16px;
    margin: 5px 0px;
}

.imageContainer {
    max-height: 120px;
}

@media only screen and (max-width: 768px) {
    .brandSection {
        justify-content: flex-start;
        width: 46.5%;
        margin: 20px 0px;
    }
    .brandName {
        font-size: 14px;
    }
    .imgClass {
        height: 100px;
        width: 100px;
    }
    .featuredInTitle {
        padding-left: 0px;
        margin-bottom: 20px;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.1em;
    }
    .featureListContainer {
        width: 90vw;
        flex-wrap: wrap;
        display: flex;
        justify-content: space-evenly;
        column-gap: 7%;
        padding: 0;
        margin: 0 auto;
    }
    .brandSectionMobile {
        width: 18.6%;
    }
    .featuredInSectionContainer {
        width: 100vw;
        padding: 6px 18px;
    }
}

@media only screen and (max-width: 600px) {
    .brandSectionMobile {
        width: 23.25%;
    }
}
@media only screen and (max-width: 500px) {
    .brandSectionMobile {
        width: 31%;
    }
}
