@import "@Static/css/common";

.root {
    display: flex;
    justify-content: center;
    min-height: 320px;
    height: 100%;
    margin: 0 40px;
    overflow: hidden;
}

.container {
    width: 100%;
    background: #fff;
    display: flex;
    flex-direction: column;

    @media (min-width: 781px) {
        justify-content: space-between;
    }
}

.contentContainer {
    position: relative;
    font-size: 16px;
    height: 60%;
    text-align: left;
    white-space: pre-wrap;
    word-break: break-word;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    color: #555555;
    padding: 0 36px 36px 36px;
}

.videoContainer {
    cursor: pointer;
    height: 300px !important;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    iframe,
    video {
        border-radius: 4px;

        // The css below ensures that the video element is always
        // according to the aspect ratio, no matter the screen size
        $aspect-ratio: 16/9;
        $responsive-width: calc(100vw - 36px);
        $min-width: 200px;
        $max-width: 100%;

        @include aspectRatio(
            $responsive-width,
            $aspect-ratio,
            $min-width,
            $max-width
        );
    }
}

.imageContainer {
    background: linear-gradient(
        51.02deg,
        rgba(28, 88, 246, 0.1) -23.15%,
        rgba(255, 255, 255, 0) 111.9%
    );
    cursor: pointer;
    display: flex;
    justify-content: center;
    .image {
        width: 100%;
        height: 100%;
        max-height: 232px;
        object-fit: cover;
        object-position: top;
    }
}

.topText {
    display: flex;
    flex-direction: column;
}

.testimonialHeading {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    max-width: 100%;
    padding-right: 12px;
    min-width: 100%;
    min-height: 24px;
    overflow-wrap: anywhere;
}
.authorContainer {
    display: flex;
    justify-content: space-between;
    padding: 34px 40px 0 40px;
    img {
        border-radius: 100%;
        width: 100px;
        height: 100px;
        object-fit: cover;
        object-position: center;
    }
    margin-bottom: 28px;
}

.authorName {
    margin-top: 12px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #1c58f6;
}

.readMore {
    padding: 0;
    font-weight: 600;
    transition: 0.2s ease all;
    &:hover {
        filter: brightness(1.1);
    }
}

.mediaOverlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 232px;
}
.testimonialHr {
    background-color: rgba(228, 232, 240, 1);
    color: rgba(228, 232, 240, 1);
}
.authorImage {
    width: 75px !important;
    height: 75px !important;
    object-fit: cover;
    object-position: center;
}
@media only screen and (max-width: 780px) {
    .root {
        width: 100% !important;
        padding: 27px 27px 0px 27px;
        margin: 0 !important;
    }
    .container {
        display: flex;
        flex-direction: column;
    }
    .authorContainer {
        padding: 25px 25px 0 25px;
        padding-bottom: 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .testimonialHeading {
        width: 100%;
        font-weight: 400;
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 24px;
    }
    .imageContainer {
        .image {
            width: 100%;
            height: 100%;
        }
    }
    .videoContainer {
        margin: -27px -27px -30px -27px;
    }
}
