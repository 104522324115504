@import "@Static/css/common";

.textContainer {
    max-width: 53vw;
    position: absolute;
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 45%;
    z-index: 3;
}

.strengthRoom {
    max-width: 100vw;
    margin: auto;
    height: 700px;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.4);
        z-index: 1;
    }

    .strengthImage {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
    }

    h1 {
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 48px;
        text-align: center;
        word-break: break-word;
        margin-bottom: 0;
    }
}
.strengthCard {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    position: relative;
    margin-top: 22px;
}

.strengthContainer {
    flex: 1;
    margin: 30px 0px;
    margin-top: 23px;
    text-align: center;
    &:last-of-type {
        border-right: none;
    }
    .strengthHead {
        font-style: normal;
        font-weight: 400;
        font-size: 64px;
        line-height: 38px;
        text-align: center;
        padding: 12px 0px;
        word-break: break-word;
        transition: all ease-in 1s;
    }
    .strengthTxt {
        word-break: break-word;
        text-transform: uppercase;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 17px;
        text-align: center;
        letter-spacing: 0.1em;
    }
}

@media (min-width: 768px) {
    .strengthContainer {
        width: 25%;
    }
    .strengthContainer:nth-child(2n) {
        margin-top: 54px;
    }
}
@media (max-width: 768px) {
    .textContainer {
        height: 90%;
        top: 16px;
        max-width: 100%;
        width: 100%;
        left: 0%;
    }
    .headingStyle {
        padding-left: 10px;
        padding-right: 10px;
    }
    .strengthContainer:nth-child(2n) {
        margin-left: 90px;
    }
    .strengthHead {
        font-style: normal;
        font-weight: 400;
        font-size: 64px;
        line-height: 92px;
        text-align: center;
        padding: 12px 0px;
    }
    .strengthTxt {
        font-size: 16px;
        word-break: break-word;
        white-space: break-spaces;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 17px;
        text-align: center;
        letter-spacing: 0.1em;
    }
    .strengthContainer {
        flex: 1;
        margin: 0px;
        text-align: center;
        &:last-of-type {
            border-right: none;
        }
    }
    .textContainer {
        h1 {
            font-size: 26px;
            line-height: 35px;
            padding-bottom: 18px;
        }
        .strengthCard {
            flex-direction: column;
            align-items: stretch;
            width: 100%;
            gap: 24px;
            white-space: nowrap;
        }
        .strengthHead {
            font-size: 64px;
        }
    }
}
