@import "@Static/css/common";

$primaryColor: getColorValue(1, (0, 0, 0));
$cardBgColor: rgb(var(--template-secondary-color-host-theme, 255, 255, 255));
$cardTextColor: getColorValue(3, (0, 0, 0));

.container {
    border-radius: 7px;
    width: 354px;
    margin: auto;
    height: 354px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    perspective: 1000px;
    scroll-snap-align: start;
}

.cardInner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
}

.cardMain {
    z-index: 2;
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: white;
}

.cardFront::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #001c66;
    opacity: 0.2;
}

.image {
    object-fit: cover;
    border-radius: 5px 5px 0px 0px;
    width: 100%;
    background-color: #ddd;

    width: 100%;
    height: 100%;

    max-width: calc(100vw - 40px);
    max-height: calc(202 / 350 * (100vw - 40px));
}

.inner {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-perspective: inherit;
    perspective: inherit;
    z-index: 2;
}

.flipBoxHeader {
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 46px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #fff;
}

.priceTag {
    position: absolute;
    bottom: 16px;
    left: -2px;
    border-radius: 6px;
    padding: 6px 20px;
    height: 48px;
    line-height: 2;
    background: #001c66;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    color: #fff;
    font-size: 24px;
    display: flex;
    align-items: center;
}

.discountPriceTag {
    background-color: #01b61e;
}

.scratchedOutPrice {
    position: absolute;
    top: 2px;
    left: 130px;
    color: #fff;
    font-size: 24px;
    font-weight: 300;
    white-space: nowrap;
}

.scratchedOutPrice::before {
    content: "";
    width: 106%;
    height: 2px;
    background: #fff;
    position: absolute;
    transform: rotate(-15deg);
    top: 22px;
}

.labelsContainer {
    position: absolute;
    top: 10px;
    margin-left: 10px;
    display: flex;
    font-size: 0.7rem;
}

.label {
    padding: 2px 6px;
    margin-right: 6px;
    border-radius: 5%;
    border-radius: 4px;
}

.cardBack {
    transform: rotateY(-180deg);
}

.cardBack::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(0, 28, 102, 0.4);
}

.innerBack {
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;
    align-items: center;
    height: 100%;
    padding: 48px 32px !important;
}

.shortDescription {
    font-style: normal;
    font-size: 18px;
    line-height: 26px;
    color: #fff !important;
}

.ctaButton {
    width: fit-content;
    max-width: 100%;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.04em;
    padding: 15px 40px;
    border-radius: 27px;
    transition: 0.2s ease all;
    &:hover {
        opacity: 0.9;
    }
}

.container:hover .cardInner {
    transform: rotateY(-180deg);
}

@media screen and (max-width: 768px) {
    .image {
        max-width: 100%;
        max-height: 100%;
    }
}
