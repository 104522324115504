@import "@Static/css/common";

.container {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    width: 370px;
}

.cardButton {
    padding: 0;
    text-align: left;
    text-decoration: none;
    font-weight: 400;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.image {
    width: 100%;
    height: 206px;
    object-fit: cover;
    object-position: center;
    border-radius: 5px 5px 0 0;
}

.infoContainer {
    flex-grow: 1;
    padding: 38px 30px 42px 30px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.fontWeightBold {
    font-weight: 700;
}

.title {
    font-size: 1rem;
    margin-bottom: 0.4rem;
}

.timeInfo {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 7px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.1em;
}
.cardTitle {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 34px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 34px;
}

.timeInfoDot {
    margin: 0 0.4rem;
}

.footer {
    margin: 0 1rem 1rem 1rem;
}

.postStatsContainer {
    display: flex;
}

.postStatContainer {
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0;
    margin-right: 0.7rem;
}

.statCount {
    margin-left: 0.3rem;
}
@media only screen and (max-width: 600px) {
    .container {
        max-width: 388px;
        width: 100%;
    }
    .cardTitle {
        width: 100%;
    }
    .infoContainer {
        padding: 40px 30px 40px 30px;
    }
}
