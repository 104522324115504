.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.heading {
    padding: 0px 100px;
    text-align: center;
    margin: 10px 0px 11px 0px;

    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 52px;
}

.description {
    padding: 0px 100px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 60px;
    text-align: center;
    white-space: pre-wrap;
}

.subtitle {
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.14em;
    text-transform: uppercase;
}

.scrollingDiv {
    position: relative;
    top: -100px;
}

@media only screen and (max-width: 600px) {
    .heading {
        padding: 0px;
        width: 100%;
        font-size: 28px;
        line-height: 40px;
    }
    .description {
        padding: 0px;
        width: 100%;
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 28px;
    }
}
