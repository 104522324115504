.root {
    padding-top: 70px;
    padding-bottom: 70px;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.heading {
    text-align: center;
    margin: 6px 0px 11px 0px;

    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 52px;
}
.description {
    width: 410px;

    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 60px;
    text-align: center;
}

.carouselContainer {
    ul {
        margin: auto;
    }
}

.dotListClass {
    bottom: -2rem;
}

.blogPostsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 35px;
}

.blogLink {
    margin: 1% 5%;
}

.uniformWidth {
    padding: 0 3rem;
}

@media only screen and (max-width: 780px) {
    .uniformWidth {
        padding: 0px;
    }
    .blogLink {
        margin: 15% 0% -10%;
    }
}

@media only screen and (max-width: 600px) {
    .root {
        padding: 22px 20px 88px 20px;
    }
    .title {
        font-weight: 500;
        font-size: 12px;
        line-height: 17px;
        text-align: center;
        letter-spacing: 0.14em;
    }
    .heading {
        width: 100%;
        font-size: 28px;
        line-height: 40px;
    }
    .description {
        width: 100%;
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 75px;
    }
    .container {
        padding-top: 44px;
    }
}
