@import "@Static/css/common";

$primaryColor: getColorValue(1, (0, 0, 0));
$cardBgColor: rgb(var(--template-secondary-color-host-theme, 255, 255, 255));
$cardTextColor: getColorValue(3, (0, 0, 0));

.root {
    border-radius: 5px;
    margin: 10px auto;
    width: 250px;
    transition: 0.2s ease all;
    cursor: pointer;
    &:hover {
        transform: translateY(-4px) scale(1.01);
    }
}
.imageRing {
    width: 180px;
    height: 180px;
    border: 1px dashed #000000;
    border-radius: 100%;
    display: flex;
    align-items: center;
    margin: auto;
}
.container {
    width: 100%;
    height: 100%;
}

.image {
    border-radius: 100%;
    width: 160px;
    height: 160px;
    background: center no-repeat;
    background-size: cover;
    margin: auto;
}
.cardBody {
    padding: 20px;
    margin: auto;
}
.iconBox {
    position: absolute;
    top: 5px;
    left: 4px;
}
.cardName {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 31px;
    text-align: center;
    letter-spacing: 0.02em;
    width: 100%;
}
.cardtitle {
    width: 100%;

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.02em;
    margin: auto;
}
.staffDescription {
    color: $cardTextColor;
    font-size: 16px;
    line-height: 20px;
    height: 24px;
    overflow: hidden;
    margin: 20px 0 0;
}
.detailsContainer {
    padding: 10px;
    font-size: 0.8rem;
}

.name {
    font-weight: 600;
}

.heading {
    font-weight: 400;
    height: 1.3rem;
    overflow-y: auto;
    @extend .plainScrollbar;
}

.description {
    height: 3.2rem;
    overflow-y: auto;
    @extend .plainScrollbar;
}
