.introVideoBg {
    width: min(1440px, 100%);
    background-repeat: no-repeat;
    background-blend-mode: overlay;
    background-size: cover;
    background-position: center;
    margin: auto;
}

.introVideoRoot {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;

    backdrop-filter: blur(8px);
}

.introVideoContainer {
    width: 100%;
    height: 100%;
    margin: 75px 0px;
    display: flex;
    gap: 90px;
    justify-content: space-evenly;
}

.textContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 2;
    margin-left: 180px;
}

.videoContainer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    margin-right: 96px;
}

.sectionName {
    font-family: Jost;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.68px;
    margin-bottom: 14px;
}

.heading {
    font-family: Jost;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 49px;
    margin-bottom: 20px;
}

.description {
    font-family: Jost;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
}

.videoPlayer {
    width: 600px;
    height: 339px;
}

@media only screen and (max-width: 1160px) {
    .introVideoContainer {
        gap: 75px;
    }

    .textContainer {
        margin-left: 150px;
    }

    .videoContainer {
        margin-right: 70px;
    }

    .videoPlayer {
        width: 500px;
        height: 282.5px;
    }
}

@media only screen and (max-width: 1050px) {
    .introVideoContainer {
        gap: 65px;
    }

    .textContainer {
        margin-left: 130px;
    }

    .videoContainer {
        margin-right: 55px;
    }

    .videoPlayer {
        width: 450px;
        height: 254.25px;
    }
}

@media only screen and (max-width: 768px) {
    .introVideoContainer {
        flex-direction: column;
        gap: 90px;
        margin: 58px 20px 70px;
    }

    .textContainer {
        margin: 0px;
    }

    .videoContainer {
        margin: 0px auto;
    }

    .videoPlayer {
        width: 420px;
        height: 237.3px;
    }
}

@media only screen and (max-width: 540px) {
    .introVideoContainer {
        gap: 80px;
        margin: 58px 20px 70px;
    }

    .textContainer {
        margin: 0px;
    }

    .videoContainer {
        margin: 0px auto;
    }

    .videoPlayer {
        width: 380px;
        height: 214.7px;
    }
}

@media only screen and (max-width: 464px) {
    .introVideoContainer {
        gap: 70px;
        margin: 58px 13px 70px;
    }

    .textContainer {
        margin: 0px;
    }

    .videoContainer {
        margin: 0px auto;
    }

    .sectionName {
        margin-bottom: 11px;
    }

    .videoPlayer {
        width: 330px;
        height: 186.45px;
    }
}
