.tabsRoot {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 60px;
    padding-left: 20px;
    padding-right: 20px;
}

.tabsContainer {
    display: flex;
    gap: 14px;
    overflow-x: scroll;
    position: relative;
}

.tabsContainer::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.tabsContainer {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.tabStyle {
    white-space: nowrap;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.previous {
    position: relative;
    margin-right: 20px;
    left: 0;
    cursor: pointer;
    top: 0;
}

.next {
    position: relative;
    margin-left: 20px;
    right: 0;
    top: 0;
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .tabsRoot {
        padding-left: 0;
        padding-right: 0;
    }
}
