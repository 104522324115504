@import "@Static/css/common";

.portfolioCard {
    height: auto;
    transition: 0.3s ease all;
    min-width: 20vw;
    width: 30%;
    &:hover {
        transform: scale(1.02) translateY(-4px);
    }
}
.cardDescription {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 29px;
    margin: 30px 30px 50px 30px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    min-height: 29px;
}
.image {
    width: 100%;
    height: 278px;
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

.video {
    width: 100%;
    height: 278px;
    position: relative;
    video {
        width: 370px;
        height: 278px;
    }
    &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .video {
        video {
            width: 100%;
            height: 278px;
        }
    }
    .portfolioCard {
        width: 250px;
    }
}

@media only screen and (max-width: 768px) {
    .image,
    .video {
        width: 100%;
    }
    .image img {
        width: 100%;
    }

    .portfolioCard {
        width: 340px;
    }
}

@media only screen and (max-width: 400px) {
    .portfolioCard {
        width: 100%;
    }
}
