@import "@Static/css/common";

.grid {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    justify-content: center;
}

.root {
    position: relative;
}

.dotListClass {
    bottom: -74px;
}

@media only screen and (max-width: 768px) {
    .grid {
        flex-direction: column;
        align-items: center;
    }
}
