@import "@Static/css/common";
$color1: getColorValue(1, (56, 36, 242));

.section {
    max-width: 1920px;
    margin: auto;
}

.carouselCont {
    width: 85vw;
    margin: 0 auto;
}

.centerCarouselCont {
    ul {
        margin: 0 auto;
    }
}

.carousalItemContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    justify-content: center;
}

.offeringsContainer {
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.eventSectionContainer {
    padding-top: 44px;
}

.carouselItem {
    display: grid;
    place-items: center;
    margin: 55px 0;
    justify-content: center;
}

.singleListing {
    margin: 44px 0px;
    display: flex;
    justify-content: center;
}

.extraPadding {
    padding-bottom: 44px;
}

.descriptionClass {
    margin-bottom: 0 !important;
}

.tabsContainer {
    justify-content: flex-start;
}

@media only screen and (max-width: 920px) {
    .carouselContainerClass {
        width: 100vw;
    }
}

@media only screen and (max-width: 768px) {
    .carouselCont {
        width: 100vw;
        margin: unset;
    }
    .eventSectionContainer {
        padding: 32px 18px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .carouselItem {
        display: grid;
        place-items: center;
        margin: 40px 0 40px 0;
    }
    .dotListClass {
        bottom: 12px;
    }
    .extraPadding {
        padding-top: 50px;
    }
}

.tabsRootStyle {
    margin-top: 44px !important;
}
