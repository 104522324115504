@import "@Static/css/common";

$primaryColor: getColorValue(1, (0, 0, 0));
$bgColor: getColorValue(2, (255, 255, 255));
$textColor: getColorValue(3, (0, 0, 0));

.root {
    padding: 64px 18px 54px 18px;
    max-width: 1920px;
    margin: auto;
}

.container {
    position: relative;
}

.heading {
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 52px;
    text-align: center;
}
.sectionTitle {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.14em;
    margin-top: 6px;
    margin-bottom: 11px;
}
.description {
    width: 410px;

    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    margin: auto;
    margin-bottom: 100px;
}
.carouselContainer {
    ul {
        margin: auto;
    }
}

.dotListClass {
    bottom: auto;
}

.sectionAnchor {
    position: relative;
    top: -120px;
    display: block;
}

@media only screen and (max-width: 768px) {
    .title {
        font-size: 20px;
        font-weight: bold;
    }
    .heading {
        font-weight: 500;
        font-size: 28px;
        line-height: 40px;
    }
    .description {
        width: 100%;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 65px;
    }
    .dotListClass {
        bottom: 12px;
    }
    .carouselContainerClass {
        padding-bottom: 50px;
    }
}
