.creatorIntro {
    background: #f9f9f9;
    padding-top: 50px;
}
.aboutTitle {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.14em;
    text-transform: uppercase;
    overflow-wrap: anywhere;
}
.aboutLine {
    width: 87px;
    height: 0px;
    border-bottom: 1.5px solid;
    margin-left: 18px;
}
.aboutText {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 36px !important;
    line-height: 48px !important;
    white-space: pre-wrap !important;
    overflow-wrap: break-word !important;
}
.aboutText p {
    margin: 0px !important;
}
.contentContainer {
    display: flex;
}
.textContainer {
    width: 100%;
}
.aboutWrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 24px;
}
.iconContainer img {
    width: 24px;
    height: 24px;
    cursor: pointer;
}
.followerCountContainer {
    margin: 0 !important;
    gap: 1rem !important;
}
.followerCountMobileItem {
    margin-bottom: 0px !important;
    margin-right: 0px !important;
}
@media only screen and (min-width: 780px) {
    .contentContainer {
        width: 85%;
        margin: auto;
        padding-bottom: 40px;
    }
}
@media only screen and (max-width: 780px) {
    .creatorIntro {
        padding: 30px;
    }
    .aboutText {
        width: 100%;
        font-size: 30px !important;
        line-height: 39px !important;
        margin-bottom: 28px !important;
    }
    .contentContainer {
        flex-direction: column;
    }
    .lineContainer {
        margin-bottom: 28px;
    }
    .aboutWrapper {
        padding-bottom: 0px;
    }
}
@media screen and (max-width: 380px) {
    .followerCountContainer {
        gap: 12px !important;
    }
}
