.paginationContainer {
    display: flex;
    gap: 10px;
    width: 100%;
    max-width: 354px;
    justify-content: space-between;
    align-items: center;
    margin: auto;
}

.iconStyles {
    cursor: pointer;
}

.paginationNumberStyles {
    font-weight: 600;
}

.customLeftArrow {
    position: absolute !important;
    bottom: 0px !important;
    left: 10px !important;
}

.customRightArrow {
    position: absolute !important;
    bottom: 0px !important;
    right: 10px !important;
}

.carousalNumberingContainer {
    position: relative;
}

.customNumbering {
    position: absolute !important;
    bottom: 0px !important;
    right: 50% !important;
}

@media screen and (min-width: 768px) {
    .paginationContainer {
        display: none;
    }
}
