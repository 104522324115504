@import "@Static/css/common";

.root {
    padding: 70px 0px 70px 0px;
    margin: auto;
    max-width: 1920px;
}

.carouselContainerClass {
    position: relative;
    left: -20%;
    right: 120%;
    width: 120%;
    ul {
        gap: 0 !important;
        li {
            width: 41.4vw !important;
        }
    }
}

.carouselContainerClassEqual {
    ul {
        gap: 0 !important;
        li {
            width: 50vw !important;
        }
    }
}

.singleTestimonial {
    max-width: 600px;
    margin: 0 auto;
}

.container {
    position: relative;
}

.extraPadding {
    padding-bottom: 120px;
}

.cardContainer {
    display: flex;
    gap: 80px;
    width: 100%;
}
.carouselContainerClass > ul {
    display: flex;
    gap: 153px;
}

.title {
    text-align: center;
    margin: 0px 0px 0px 0px;

    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.14em;
}
.sectionHeading {
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 52px;
    text-align: center;
}
.sectionDescription {
    width: 410px;

    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    margin: auto;
    margin-bottom: 50px;
}

.sectionAnchor {
    position: relative;
    top: -156px;
    display: block;
}

@media only screen and (min-width: 768px) {
    .container {
        &.singleTestimonialContainer > div:not(.title) {
            justify-content: center !important;
        }
    }
    .dotListClass {
        bottom: -60px;
    }
}
@media only screen and (max-width: 780px) {
    .root {
        padding: 30px 16px 120px;
        background: linear-gradient(to bottom, #ebf4f8 60%, transparent 40%);
    }
    .testimonialItem {
        display: grid;
        place-items: center;
        width: calc(100vw - 16px) !important;
    }
    .title {
        font-weight: 500;
        font-size: 12px;
        line-height: 17px;
        text-align: center;
        letter-spacing: 0.14em;
    }
    .dotListClass {
        bottom: -60px;
    }
    .sectionHeading {
        font-weight: 500;
        font-size: 28px;
        line-height: 40px;
    }
    .sectionDescription {
        width: 100%;
        margin: auto;
        margin-bottom: 76px;
    }
    .carouselContainerClass > ul {
        gap: 20px;
    }
    .carouselContainerClass,
    .carouselContainerClassEqual {
        position: relative !important;
        left: -20px !important;
        width: 100vw !important;
        ul {
            gap: 0px !important;
            li {
                width: 100vw !important;
            }
        }
    }
    .singleTestimonial {
        width: 100vw;
        max-width: 100vw !important;
        margin: 0 0 0 -16px !important;
    }
}
